import { WithDestroy } from '@aex/ngx-toolbox';
import { Component } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';
import { ManagePremisesService } from '../../services/manage-premises.service';
import {
    ICity,
    IProvince,
    IArea,
    IPredefinedPremise,
    IClientPremise,
} from '../../services/types';

@Component({
    selector: 'app-manage-premises',
    templateUrl: './manage-premises.component.html',
    styleUrls: ['./manage-premises.component.scss'],
})

export class ManagePremisesComponent extends WithDestroy() {
    filtered = false;
    predefinedPremiseIndex = 0;

    public SelectedProvince: IProvince;
    public SelectedCity: ICity;
    public SelectedArea: IArea;
    public SelectedPredefinedPremise: IPredefinedPremise;

    public Provinces: IProvince[];
    public Cities: ICity[];
    public Areas: IArea[];
    public filteredAreas: IArea[];

    public PredefinedPremises: IPredefinedPremise[];
    public filteredPredefinedPremises: IPredefinedPremise[];

    public ClientPremise: IClientPremise;

    public ProvinceLabel = this.configService.config.customPhrases?.Province ?? 'Province';
    public CityLabel = this.configService.config.customPhrases?.City ?? 'City';
    public AreaLabel = this.configService.config.customPhrases?.Area ?? 'Area';
    public PremiseLabel = this.configService.config.customPhrases?.Premise ?? 'Premise';

    constructor(
        public readonly managePremisesService: ManagePremisesService,
        public readonly configService: ConfigService,
        private readonly toast: ToastrService,
    ) {
        super();

        this.managePremisesService.getProvinces().subscribe(p => this.Provinces = p.items);
    }

    get filterLabel() {
        return `${this.SelectedProvince.name}, ${this.SelectedCity.name}, ${this.SelectedArea.name}`;
    }

    get predefinedPremisedSelected() {
        return this.SelectedPredefinedPremise !== null;
    }

    get predefinedPremiseId() {
        return this.SelectedPredefinedPremise.id;
    }

    public displayArea(area: IArea): string {
        return area && area.name ? area.name : '';
    }

    public displayPredefinedPremise(predefinedPremise: IPredefinedPremise): string {
        return predefinedPremise && predefinedPremise.full_text ? predefinedPremise.full_text : '';
    }

    public previousPremise() {
        if (this.predefinedPremiseIndex > 0)
            this.predefinedPremiseIndex--;

        this.updatedSelectedPremise();
    }

    public nextPremise() {
        if (this.predefinedPremiseIndex < this.PredefinedPremises.length)
            this.predefinedPremiseIndex++;

        this.updatedSelectedPremise();
    }

    public onProvinceChange(province: IProvince): void {
        this.SelectedProvince = province;
        this.SelectedCity = null;
        this.filteredAreas = [];
        this.SelectedArea = null;
        this.PredefinedPremises = [];
        this.filteredPredefinedPremises = [];
        this.SelectedPredefinedPremise = null;
        this.filtered = false;

        this.managePremisesService.getCities(province.id)
            .subscribe((result) => {
                this.Cities = result.items;
            });

    }

    public onCityChange(city: ICity): void {
        this.SelectedCity = city;
        this.filteredAreas = [];
        this.SelectedArea = null;
        this.PredefinedPremises = [];
        this.filteredPredefinedPremises = [];
        this.SelectedPredefinedPremise = null;
        this.filtered = false;

        this.managePremisesService.getAreas(city.name, this.SelectedProvince.name)
            .subscribe((result) => {
                if (result.items.length === 0) {
                    this.Areas = [];
                    this.toast.info(`City "${city.name}" has no Areas`);
                } else
                    this.Areas = result.items;

            });
    }

    public onAreaChange(areaSelected: MatAutocompleteSelectedEvent): void {
        const area: IArea = areaSelected.option.value;

        if (!area) return;

        this.SelectedArea = area;
        this.predefinedPremiseIndex = 0;
        this.PredefinedPremises = [];
        this.filteredPredefinedPremises = [];
        this.SelectedPredefinedPremise = null;

        this.managePremisesService.getPredefinedPremises(area.id)
            .subscribe((result) => {
                if (result.items.length === 0) {
                    this.filtered = false;
                    this.toast.info(`Area "${area.name}" has no premises`);
                } else {
                    this.PredefinedPremises = result.items;
                    this.updatedSelectedPremise();
                    this.filtered = true;
                }
            });
    }

    public onPredefinedPremiseChange(predefinedPremiseSelected: MatAutocompleteSelectedEvent): void {
        const predefinedPremise: IPredefinedPremise = predefinedPremiseSelected.option.value;

        if (!predefinedPremise) return;

        this.predefinedPremiseIndex = this.PredefinedPremises.indexOf(predefinedPremise);

        this.updatedSelectedPremise();
    }

    public updatedSelectedPremise(): void {
        this.SelectedPredefinedPremise = this.PredefinedPremises[this.predefinedPremiseIndex];
    }

    public filterAreas(value: string | IArea) {
        const area = value ? (typeof value === 'string' ? value : value.name) : '';
        this.filteredAreas = this._filterAreas(area);
    }

    private _filterAreas(area: string): IArea[] {
        const filterValue = area?.toLowerCase();

        return this.Areas.filter(area => area.name.toLowerCase().includes(filterValue));
    }

    public filterPredefinedPremises(value: string | IPredefinedPremise) {
        const predefinedPremise = value ? (typeof value === 'string' ? value : value.full_text) : '';
        this.filteredPredefinedPremises = this._filterPredefinedPremises(predefinedPremise);
    }

    private _filterPredefinedPremises(predefinedPremise: string): IPredefinedPremise[] {
        const filterValue = predefinedPremise?.toLowerCase();

        return this.PredefinedPremises.filter(p => p.full_text.toLowerCase().includes(filterValue));
    }
}


