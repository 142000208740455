import { pathify } from '@aex/ngx-toolbox';
import { Guid } from 'guid-typescript';
import { LOCATION_RICA } from '../_shared/types';
import { assetsServerUrl, purchaseServiceUrl, securityServiceUrl, serverUrl } from '../_shared/utils';

export class AuthApi {

	public static get auth(): string { return securityServiceUrl('auth'); }

	public static get azureActiveDirectoryAuth(): string { return securityServiceUrl('auth', 'token', 'from-active-directory'); }

}

export class ConfigApi {

	public static get dynamicEnvironment(): string { return pathify('assets', 'environment', 'app-config.json'); }

	public static config = (name: string) => pathify('assets', 'config', `config.${name}.json`);

}

const WORK_ORDERS = 'work-orders';

export class InstallsApi {

	public static get installs(): string { return serverUrl('installs'); }

	public static get nidInstalls(): string { return serverUrl('nid-installs'); }

	public static install = (id: string) => serverUrl('installs', id);
	public static nidInstall = (id: string) => serverUrl('nid-installs', id);

	public static get repairs(): string { return serverUrl('repairs'); }

	public static repair = (id: string) => serverUrl('repairs', id);

	public static get preorders(): string { return serverUrl('pre-orders'); }

	public static preorder = (id: string) => serverUrl('pre-orders', id);

	public static get relocations(): string { return serverUrl('relocations'); }

	public static relocation = (id: string) => serverUrl('relocations', id);

	public static get faults(): string { return serverUrl('faults'); }

	public static fault = (id: string) => serverUrl('fault', id);

	public static workOrder = (id: string) => serverUrl(WORK_ORDERS, id);

}

export class StockApi {

	public static get stock(): string { return serverUrl('assets', 'stock'); }

	public static workOrder = (id: string, useAssetIntegrationService: boolean = false) =>
		AssetsApi.assets('work-order', id, 'stock', useAssetIntegrationService);
}


export class WorkOrderAssetsApi {

	public static workOrderAssets = (id: string, type: string, useAssetIntegrationService: boolean = false) =>
		AssetsApi.assets('work-order', id, type, useAssetIntegrationService);
}

export class ServiceAssetsApi {

	public static get serviceAssetAttributes(): string { return serverUrl('assets', 'service'); }

	public static serviceAssets = (id: string, useAssetIntegrationService: boolean = false) =>
		AssetsApi.assets('service', id, 'service', useAssetIntegrationService);
}

export class ServicesApi {

	public static fullService = (id: string) => serverUrl('services', id, 'full');

	public static service = (id: string) => serverUrl('services', id);

}

export class DevicesApi {

	public static checkFsan = (fsan: string) => serverUrl('devices', fsan, 'check');
	public static device = (macAddress: string) => serverUrl('devices', macAddress, 'services');
	public static deviceStatus = (serialNumber: string) => serverUrl('noc', 'devices', serialNumber);
	public static configureDevice = (serialNumber: string) => serverUrl('noc', 'devices', serialNumber, 'configure-layer-3');
}

export class UtilityApi {

	public static get processBarcode(): string { return serverUrl('process', 'barcode'); }

}

export class AssetsApi {

	public static get images(): string { return serverUrl('assets', 'images'); }

	public static assets(objectType: string, objectId: string, assetType: string, useAssetIntegrationService: boolean = false): string {
		if (useAssetIntegrationService)
			return assetsServerUrl('object-types', objectType, 'objects', objectId, 'asset-types', assetType, 'assets');
		else
			return serverUrl('assets', assetType, objectType, objectId);
	}
}

const FILES = 'files';

export class FilesApi {

	public static get files(): string { return serverUrl(FILES); }

	public static file = (id: string) => serverUrl(FILES, id);

	public static download = (id: number) => serverUrl(FILES, id, 'download');

	public static fileLocation = (location: string) => serverUrl(location, FILES);
}

export class RicaApi {

	public static customer = (id: string) => serverUrl(LOCATION_RICA, id);

}

export class WorkOrderHistoryApi {

	public static get workOrders(): string { return serverUrl(WORK_ORDERS); }

	public static workorderHistory = (workorderId: string) => serverUrl(WORK_ORDERS, workorderId, 'history');

}

export class WorkOrderApi {

	public static companies = (workOrderId: Guid, statusId: Guid) => serverUrl(WORK_ORDERS, workOrderId.toString(), statusId.toString());

	public static companyPeople = (workOrderId: Guid, statusId: Guid, companyId: Guid) =>
		serverUrl(WORK_ORDERS, workOrderId.toString(), statusId.toString(), companyId.toString());
}

export class PurchaseApi {
	public static validMedium1 = (customerId: string) => purchaseServiceUrl('valid-medium', customerId);

	public static get validMedium(): string { return purchaseServiceUrl('valid-payment-medium'); }

}

export class PredefinedPremisesApi {
	public static get predefinedPremises(): string { return serverUrl('predefined-premises'); }
	public static layer3Details = (predefinedPremiseId: number) => serverUrl('predefined-premises', predefinedPremiseId.toString(), 'layer-3-details');


}

export class ClientPremiseApi {
	public static get clientPremises(): string { return serverUrl('client-premises'); }
	public static clientPremise = (id: number) => serverUrl('client-premises', id);
}

export class AreaApi {
	public static get areas(): string { return serverUrl('areas'); }
	public static get searchAreas(): string { return serverUrl('areas', 'search'); }
}

export class CitiesApi {
	public static get cities(): string { return serverUrl('cities'); }
}

export class ProvincesApi {
	public static get provinces(): string { return serverUrl('provinces'); }
}