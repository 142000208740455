<div class="manage-premises mat-elevation-z2" fxLayout="column" fxLayoutGap=".5rem">
    <div class="manage-premises-header" fxLayout fxLayoutAlign="space-between center">
        <div>Manage Premises</div>
    </div>
    <mat-accordion>
        <mat-expansion-panel [expanded]="!filtered">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filter Premises
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field appearance="standard">
                <mat-label>{{ ProvinceLabel }}</mat-label>
                <mat-select id="provinceField"
                            placeholder="{{ ProvinceLabel }}"
                            name="province"
                            [ngModel]="SelectedProvince"
                            (selectionChange)="onProvinceChange(provinceField.value)"
                            #provinceField="ngModel">
                    <mat-option *ngFor="let province of Provinces" [value]="province">{{ province.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="provinceField.errors?.required">Please select a province</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>{{ CityLabel }}</mat-label>
                <mat-select id="cityField"
                            placeholder="{{ CityLabel }}"
                            name="city" 
                            [ngModel]="SelectedCity"
                            (selectionChange)="onCityChange(cityField.value)"
                            #cityField="ngModel">
                    <mat-option *ngFor="let city of Cities" [value]="city">{{ city.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard">
                <mat-label>{{ AreaLabel }}</mat-label>
                <input type="text"
                       placeholder="{{ AreaLabel }}"
                       matInput
                       [ngModel]="SelectedArea"
                       [matAutocomplete]="areaAutocomplete"
                       (ngModelChange)="filterAreas($event)">
                <mat-autocomplete #areaAutocomplete="matAutocomplete" [displayWith]="displayArea" (optionSelected)="onAreaChange($event)">
                    <mat-option *ngFor="let area of filteredAreas"
                                [value]="area">
                        {{ area.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="filtered" fxLayout="column" fxLayoutGap=".5rem" fxFlexAlign="center">
        <span class="filter" fxFlexAlign="center">
            {{ filterLabel }}
        </span>
        <mat-divider></mat-divider>
        <mat-form-field appearance="standard" fxFlexAlign="center">
            <mat-label>{{ PremiseLabel }}</mat-label>
            <input type="text"
                   placeholder="{{ PremiseLabel }}"
                   matInput
                   [ngModel]="SelectedPredefinedPremise"
                   [matAutocomplete]="premiseAutocomplete" 
                   (ngModelChange)="filterPredefinedPremises($event)">
            <mat-autocomplete #premiseAutocomplete="matAutocomplete" [displayWith]="displayPredefinedPremise" (optionSelected)="onPredefinedPremiseChange($event)">
                <mat-option *ngFor="let predefinedPremise of filteredPredefinedPremises"
                            [value]="predefinedPremise">
                    {{ predefinedPremise.full_text }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <br />
        <app-premises *ngIf="predefinedPremisedSelected" [predefinedPremiseId]="predefinedPremiseId"></app-premises>
        <br />
        <mat-divider></mat-divider>
        <div fxLayout fxLayoutAlign="space-between center">
            <button mat-button color="warn" (click)="previousPremise()">Previous</button>
            <button mat-button color="primary" (click)="nextPremise()">Next</button>
        </div>
    </div>
</div>