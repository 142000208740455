import { WithDestroy } from '@aex/ngx-toolbox';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../../services/config.service';
import { ManagePremisesService } from '../../../services/manage-premises.service';
import { BeaconType, IClientPremise, ILayer3Config } from '../../../services/types';

@Component({
    selector: 'app-premises',
    templateUrl: './premises.component.html',
    styleUrls: ['./premises.component.scss'],
})

export class PremisesComponent extends WithDestroy() implements OnChanges {

    @Input() public predefinedPremiseId: number;
    private _clientPremise: IClientPremise;
    private _clientPremiseConfig: ILayer3Config;
    private _enableOpenSSID: boolean = true;
    private _existingFsan: string;
    private _deviceConfigured: boolean = false;

    public fsanLabel = this.configService.config.customPhrases?.fsan ?? 'FSAN';

    constructor(
        public readonly managePremisesService: ManagePremisesService,
        public readonly configService: ConfigService,
        private readonly toast: ToastrService,
    ) {
        super();
        this._clientPremise = { id: 0, full_address: null, asset_reference: null, nid: false, predefined_premise_id: this.predefinedPremiseId } as IClientPremise;
    }

    get id() {
        return this._clientPremise?.id;
    }

    get nid() {
        return this._clientPremise?.nid;
    }

    set nid(enabled: boolean) {
        if (this._clientPremise)
            this._clientPremise.nid = enabled;
    }

    get assetReference() {
        return this._clientPremise?.asset_reference;
    }

    set assetReference(assetReference: string) {
        this._clientPremise.asset_reference = assetReference;
    }

    get pppoeUsername() {
        return this._clientPremiseConfig?.pppoe_username;
    }

    get pppoeUsernameExists() {
        return this._clientPremiseConfig?.pppoe_username !== null;
    }

    get pppoePassword() {
        return this._clientPremiseConfig?.pppoe_password;
    }

    get pppoePasswordExists() {
        return this._clientPremiseConfig?.pppoe_password !== null;
    }

    get wifiUsername() {
        return this._clientPremiseConfig?.wifi_username;
    }

    get wifiUsernameExists() {
        return this._clientPremiseConfig?.wifi_username !== null;
    }

    get wifiPassword() {
        return this._clientPremiseConfig?.wifi_password;
    }

    get wifiPasswordExists() {
        return this._clientPremiseConfig?.wifi_password !== null;
    }

    get enableOpenSSID() {
        return this._enableOpenSSID;
    }

    set enableOpenSSID(enabled: boolean) {
        this._enableOpenSSID = enabled;
    }

    get fsanExists() {
        return this._existingFsan !== null;
    }

    get deviceConfigured() {
        return this._deviceConfigured;
    }

    ngOnChanges(_changes: SimpleChanges) {
        this.refreshPremise();
    }

    public refreshPremise() {
        this.managePremisesService.getClientPremise(this.predefinedPremiseId)
            .subscribe((result) => {
                if (result.items.length === 0)
                    this.toast.error(`Cannot find premises details`);
                else {
                    this._clientPremise = result.items[0];
                    this._existingFsan = this._clientPremise.asset_reference;
                }
            });
        this.managePremisesService.getPredefinedPremisesLayer3Details(this.predefinedPremiseId)
            .subscribe((result) => {
                this._clientPremiseConfig = result;
            });

        this._deviceConfigured = false;
    }

    public save() {

        this.managePremisesService.validateFsan(this._clientPremise.asset_reference).subscribe(
            result => {
                if (result.premise_id[0] !== null && result.errors[0] === 'Device is ready for provisioning') {
                    this.toast.success(`${this.fsanLabel} is valid`);

                    this.managePremisesService.updateClientPremise(this._clientPremise.id, {
                        asset_reference: this._clientPremise.asset_reference,
                        nid: this._clientPremise.nid,
                    }).subscribe(
                        result => {
                            this._clientPremise = result;
                            this._existingFsan = this._clientPremise.asset_reference;
                            this.toast.success('Premise updated');
                        },
                        error => {
                            this.toast.error(error?.error?.errors[0] ?? 'Failed to update premise');
                        });
                }
                else
                    this.toast.warning(`Another premise is already associated with ${this.fsanLabel}`);
            },
            error => {
                this.toast.error(`Error: ${error?.error?.errors[0] ?? `Failed to validate ${this.fsanLabel}`}`);
            });
    }

    public configureDevice() {
        this.managePremisesService.configureDevice(this._clientPremise.asset_reference, {
            pppoe_username: this._clientPremiseConfig.pppoe_username,
            pppoe_password: this._clientPremiseConfig.pppoe_password,
            wifi_username: this._clientPremiseConfig.wifi_username,
            wifi_password: this._clientPremiseConfig.wifi_password,
            beacon_type: (this.enableOpenSSID ? BeaconType.Basic : BeaconType.Wpa2),
        }).subscribe(
            result => {
                this._deviceConfigured = true;
                this.toast.success(result.message);
            },
            error => {
                this.toast.error(error?.error?.errors[0] ?? 'Failed to configure device');
            });
    }

    public copyToClipboard(clipboardText: string): void {
        navigator.clipboard.writeText(clipboardText).then(() => this.toast.success('Copied to Clipboard'));
    }
}