import { IPagedResponse } from '@aex/ngx-toolbox';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { AuthType, ParamMetaData } from '../_shared/param-meta-data';
import { PredefinedPremisesApi, ClientPremiseApi, AreaApi, ProvincesApi, CitiesApi, DevicesApi } from './api';
import { IArea, IProvince, ICity, IPredefinedPremise, IClientPremise, ILayer3Config, IDeviceConfig } from './types';

@Injectable({ providedIn: 'root' })
export class ManagePremisesService {

    public get managePremisesMeta(): ParamMetaData { return new ParamMetaData({ handleError: 'manage-premises', authToken: AuthType.USER }); }

    constructor(
        private readonly http: HttpClient,
    ) { }

    public getCities(provinceId: number, count: number = 300, page: number = 1): Observable<IPagedResponse<ICity>> {
        return this.http.get<IPagedResponse<ICity>>(CitiesApi.cities, {
            params: this.managePremisesMeta
                .set('province_id', provinceId)
                .set('count', count)
                .set('page', page),
        });
    }

    public getProvinces(count: number = 300, page: number = 1): Observable<IPagedResponse<IProvince>> {
        return this.http.get<IPagedResponse<IProvince>>(ProvincesApi.provinces, {
            params: this.managePremisesMeta
                .set('count', count)
                .set('page', page),
        });
    }

    public getAreas(city: string, province: string, count: number = 1000, page: number = 1): Observable<IPagedResponse<IArea>> {
        return this.http.get<IPagedResponse<IArea>>(AreaApi.areas, {
            params: this.managePremisesMeta
                .set('city', city)
                .set('province', province)
                .set('count', count)
                .set('page', page),
        });
    }

    public searchAreas(name: string, city: string, province: string): Observable<IPagedResponse<IArea>> {
        return this.http.post<IPagedResponse<IArea>>(AreaApi.searchAreas, {
            name,
            city,
            province,
        },
            {
                params: this.managePremisesMeta
                    .set('count', 'all')
                    .set('page', 1),
            });
    }

    public getPredefinedPremises(areaId: Guid, count: number = 1000, page: number = 1): Observable<IPagedResponse<IPredefinedPremise>> {
        return this.http.get<IPagedResponse<IPredefinedPremise>>(PredefinedPremisesApi.predefinedPremises, {
            params: this.managePremisesMeta
                .set('area_id', areaId.toString())
                .set('count', count)
                .set('page', page)
                .set('method', 'db'),
        });
    }

    public getPredefinedPremisesLayer3Details(predefinedPremiseId: number): Observable<ILayer3Config> {
        return this.http.get<ILayer3Config>(PredefinedPremisesApi.layer3Details(predefinedPremiseId), {
            params: this.managePremisesMeta,
        });
    }

    public getClientPremise(predefinedPremiseId: number, count: number = 1, page: number = 1): Observable<IPagedResponse<IClientPremise>> {
        return this.http.get<IPagedResponse<IClientPremise>>(ClientPremiseApi.clientPremises, {
            params: this.managePremisesMeta
                .set('predefined_premise_id', predefinedPremiseId)
                .set('count', count)
                .set('page', page),
        });
    }

    public updateClientPremise(clientPremiseId: number, clientPremise: Partial<IClientPremise>): Observable<IClientPremise> {
        return this.http.put<IClientPremise>(ClientPremiseApi.clientPremise(clientPremiseId), { client_premise: clientPremise }, {
            params: this.managePremisesMeta,
        });
    }

    public configureDevice(serialNumber: string, deviceConfig: IDeviceConfig): Observable<any> {
        return this.http.post<any>(DevicesApi.configureDevice(serialNumber), deviceConfig, {
            params: this.managePremisesMeta,
        });
    }

    public validateFsan(fsan: string): Observable<any> {
        return this.http.post<any>(DevicesApi.checkFsan(fsan), null);
    }
}